<template>
<div style="max-with: 300px; min-with: 300px" class="d-flex justify-content-center">

  <div id="chart"></div>
</div>
</template>

<script>
import ApexCharts from 'apexcharts'
import { mapState } from 'vuex';
import { map, size } from 'lodash'
import { currencyEqual } from '@/core/filters/currency'
import moment from 'moment';
moment.locale('pt-br')
const namespaceStore = 'organizzeDespesaReceita'
export default {
  name: 'testeGrafico',
  props:['formatLabel'],
  data () {
    return {
      count: 0,
      element: null,
      options:{
        theme: {
          mode: 'dark', 
          monochrome: {
            enabled: false
          }
        },
        colors:['#008ffb','#ffde61', '#d7263d', '#47ca82'],
        noData: {
          text: 'Carregando...'
        },
        series: [{
            name: 'Receitas',
            type: 'column',
            data: [],
          },
          {
            name: 'Custeio',
            type: 'column',
            data: []
          },
          {
          name: 'Saldo',
          type: 'line',
          data: []
        }, 
        {
          name: 'Despesas',
          type: 'column',
          data: []
        }],
        fill: {
          type: "gradient",
        },
        chart: {
          height: 400
        },
        stroke: {
          curve: 'smooth'
        },
        dataLabels:{
          style: {
            // colors: ['#F44336', '#E91E63']
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
          }
        },
        grid: {
          row: {
            // colors: ['#fff', '#f2f2f2']
          },
          column: {
            // colors:  ['#F44336', '#E91E63']
          }
        },
        markers: {
          size: 5,
          radius: 2,
          colors:['#008ffb','#ffde61', '#d7263d', '#47ca82'],
          hover: {
            size: 10,
            sizeOffset: 3
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return currencyEqual(value)
            }
          }
        },
        xaxis: {
          categories: [],
          label: {
            formatter: (v) => {
              return v || ''
            }
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (v) => {
              return currencyEqual(v)
            }
          }
        }
      }
    }
  },
  mounted () {
    this.element = new ApexCharts(document.querySelector("#chart"), this.options)
    this.element.render()
    this.updateSeries()

  },
  watch: {
    'result' (val) {
      this.updateSeries(val)
      this.count++
    }
  },
  computed: {
    ...mapState(namespaceStore, ['result']),
    formatData () {
      // eslint-disable-next-line
      let count = this.count // bug nao atualiza se nao trocar o label
      const label = this.formatLabel
      let dados = [{
          name: 'Saldo',
          type: 'line',
          data: []
        },
        {
          name: 'Despesas',
          type: 'colunm',
          data: []
        },
        {
          type: 'colunm',
          data: []
      }]
      const result = this.result
      if (size(result) === 0) {
        return dados
      }

      const saldo = map(result.data, v => (parseFloat(v.Records_balance).toFixed(3) * -1))
      const receita = map(result.data, v => parseFloat(v.Records_outcome).toFixed(3) * -1)
      const despesa = map(result.data, v => parseFloat(v.Records_income).toFixed(3) * -1)
      const custeio = map(result.data, v => parseFloat(v.costing.Records_income || 0).toFixed(3) * -1)

      const labels = map(result.data, v => {
        if (label === 'day') {
          return moment(v.Records_Date).format('DD/MM/YYYY')
        }
        if (label === 'week') {
          let format = moment().weeks(v.Records_Week).year(v.Records_Year).format('DD/MM/YYYY')
          return format
        }
        return `${v.Records_Month}/${v.Records_Year}`
      })
      return { saldo, receita, despesa, labels, custeio }
    }
  },  
  methods: {
    updateSeries (val) {
      if (size(val) === 0) {
         this.element.updateOptions({
           series: [],
           noData: {
             text: 'Não existe dados!'
           }
         })
        return
      }
      this.element.updateOptions({
      series: [{
        name: 'Receitas',
        type: 'column',
        data: this.formatData.receita
      },
      {
        name: 'Custeio',
        type: 'column',
        data: this.formatData.custeio
      },
      {
        name: 'Despesas',
        type: 'column',
        data: this.formatData.despesa
      },
      {
        name: 'Saldo',
        type: 'line',
        data: this.formatData.saldo
      }],
      xaxis: {
        categories: this.formatData.labels
      }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#chart {
  text-align: center;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
</style>